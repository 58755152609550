<template>
    <div class="works">
        <left-menu current="works" />
        <div class="main">
            <div class="wrap">
                <table class="list" v-if="total" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th class="cover-cell">专辑封面</th>
                            <th class="name-cell">专辑名称</th>
                            <th class="cnt-cell">歌曲数</th>
                            <th class="status-cell">状态</th>
                            <th class="time-cell">首发时间</th>
                            <th class="action-cell">操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, index) in list">
                        <tr :key="index">
                            <td>
                                <img :src="item.cover" class="cover" />
                            </td>
                            <td>
                                <div class="work-name">
                                    {{item.name}}
                                </div>
                            </td>
                            <td>
                                {{item.songNum}}
                            </td>
                            <td class="status-cell">
                                <div class="flex-center" :class="'status' + item.status">
                                    {{item.status|statusText}}
                                    <div class="question" v-if="item.examineDesp && item.examineDesp !=''">
                                        <img src="../assets/question.png" class="icon" />
                                        <div class="tips">{{item.examineDesp}}</div>
                                    </div>
                                </div>

                            </td>
                            <td class="time-cell">
                                {{ item.firstRoundTime ? $timestampToString(item.firstRoundTime) : '未发行'}}
                            </td>
                            <td class="action-cell">
                                <div v-if="item.status == 4" @click="$router.replace('/publish?id=' + item.id)" class="show-detail">
                                    修改
                                    <img src="../assets/triangle-black.png" class="triangle" :class="{rotate: item._showDetail}" />
                                </div>
                                <div v-else @click="toggleDetail(index)" class="show-detail">
                                    详情
                                    <img src="../assets/triangle-black.png" class="triangle" :class="{rotate: item._showDetail}" />
                                </div>

                            </td>
                        </tr>
                            <template v-if="item._showDetail && item._childs && item._childs.length">
                                <tr class="detail" v-for="(song, songIndex) in item._childs" :key="song.id">
                                    <td class="no">{{songIndex+1}}</td>
                                    <td>
                                        <div class="work-name">
                                            {{song.name}}
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>
                                        <div class="flex-center" :class="'status' + song.status">
                                            {{song.status|statusText}}
                                            <div class="question" v-if="song.examineDesp && song.examineDesp !=''">
                                                <img src="../assets/question.png" class="icon" />
                                                <div class="tips">{{song.examineDesp}}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td v-if="songIndex == 0" :rowspan="item.songNum" class="edit-wrap">
                                        <div class="edit" v-if="item.status == 2 || song.status == 2" @click="edit(index)">
                                            <img src="../assets/edit.svg" class="icon" />
                                            修改
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="pager" v-if="total" >
                <pagination :current="page" :total="total" :pageSize="pageSize" @change="onPageChange" @pageSizeChange="onPageSizeChange" />
            </div>
            <div class="empty" v-else>
                <img src="../assets/empty.svg" class="icon" />
                <div class="text">暂无作品～</div>
            </div>
        </div>
    </div>
</template>
<script>
import LeftMenu from '../components/LeftMenu.vue'
import Pagination from '../components/Pagination.vue'
export default {
    components: { LeftMenu, Pagination },
    filters:{
        statusText(status){
            return { '-1': '删除', 0: '待审核', 1: '审核成功', 2: '审核失败', 3: '已发行', 4: '草稿'}[status]
        }
    },
    data(){
        return {
            list:[],
            page: 1, // 当前页码
            pageSize: 10, // 页大小
            totalPages: -1, // 总页数,-1表示还没加载
            total: -1, // 总条数,-1表示还没加载
        }
    },
    created(){
        this.loadList()
    },
    methods:{
        // 切页
        onPageChange(e){
            this.page = e.current
            this.loadList()
        },
        async loadList(){
            let res = await this.$post('/api/song/albumList', {
            "pageNum": this.page,
            "pageSize": this.pageSize
            })
            this.list = res.data.list
            this.total = res.data.total
            this.totalPages = res.data.pages
        },
        // 显示、隐藏详情
        async toggleDetail(index){
            if(this.list[index]._showDetail){
                return this.list[index]._showDetail = false
            }
            this.$set(this.list[index], '_showDetail', true)
            if(this.list[index]._childs){
                return
            }
            let res = await this.$post("/api/song/songList?albumId=" + this.list[index].id)
            this.$set(this.list[index], '_childs', res.data)
        },
        // 修改专辑
        edit(index){
            this.$router.replace("/publish?id=" + this.list[index].id)
        },
        // 改变页码大小
        onPageSizeChange(ps){
            this.pageSize = ps
            this.page = 1
            this.loadList()
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/styles/works.scss';
.main{
    min-height: 860px;
    display: flex;
    flex-direction: column;
    .wrap{
        flex: 1;
    }
}
.list{
    width: 100%;
    padding: 0 30px;
    .cover-cell{
        text-align: left;
        width: 64px;
    }
    .name-cell{
        width: 330px ;
    }
    .cnt-cell{
        width: 49px;
    }
    .status-cell{
        width: 217px;
    }
    .time-cell{
        width: 135px;
        text-align: left;
    }
    .action-cell{
        width: 105px;
        /* text-align: right; */
    }
    thead th{
        padding: 30px 0;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
    }
    tbody td{
        padding: 20px 0;
        height: 16px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        /* display: flex;
        align-items: center;
        justify-content: center; */
        text-align: center;
        vertical-align: middle;
        border-top:1px solid #F1F1F1;
        .show-detail{
            cursor: context-menu;
        }
        .triangle{
            width: 12px;
            height: 12px;
            margin-left: 1px;
        }
        .rotate{
            transform: rotate(180deg);
        }
        .cover{
            width: 60px;
            height: 60px;
            display: block;
        }
        &.edit-wrap{
            padding:0;
            padding-left:20px;
            width:105px;
            box-sizing: border-box;
            background: #FFFFFF;
        }
        .edit{
            background: $base-color;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            cursor: pointer;
            .icon{
                width: 10px;
                height: 12px;
                margin-right: 4px;
            }
        }
        .work-name{
            width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
        }
    }
    .detail{
        background: #FBFBFB;
    }
    .detail td{
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        border-top: none;
    }
    .detail td.no{
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #666666;
    }
    .question{
        width: 15px;
        height: 15px;
        margin-left: 4px;
        vertical-align: middle;
        position: relative;
        .icon{
            width: 15px;
            height: 15px;
        }
        .tips{
            display: none;
            /* height: 45px; */
            background: #FFFFFF;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
            border: 1px solid #EAEAEA;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 30px;
            z-index: 1;
            white-space: nowrap;
            padding: 11px 16px;
            color: #666666;
        }
        &:hover{
            .tips{
                display: block;
            }
        }
    }
    .status1{
        color: #00AD11 !important;
    }
    .status2{
        color: #EC7E00;
        position: relative;

    }
    .status0{
        color: #666666 !important;
    }
}
.empty{
    height: 859px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon{
        width: 112px;
        height: 105px;
    }
    .text{
        margin-top: 16px;
        height: 15px;
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #222222;
        line-height: 14px;
    }
}
.pager{
    padding: 20px;
}
</style>
